<template>
  <div>
      <LiefengContent>
        <template v-slot:title>设备分类管理</template>
        <template v-slot:toolsbarRight>
                <Input
                  :maxlength="20"
                  enter-button
                  placeholder="请输入类型名称"
                  style="margin-right:10px;width: 150px"
                  v-model="search.name"
                />
                <Input
                  :maxlength="20"
                  width="100px"
                  placeholder="请输入类型编码"
                  style="margin-right:10px;width:150px"
                  v-model="search.code"
                />
              <Button type="primary" icon="ios-search" @click="searchBtn">搜索</Button>
              <Button type="success" icon="ios-refresh" @click="restBtn">重置</Button>
              <Button type="primary"  icon="ios-add"  style="margin-right: 10px">新增</Button>
        </template>
        <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        </template>
      </LiefengContent>
  </div>
</template>

<script>
  //@route("/equipmentcommindex")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengTable,LiefengModal},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                title: "类型名称",
                key: "name",
                minWidth: 200,
                align: "center"
            },
              {
                title: "类型编码",
                  key: "code",
                  minWidth: 200,
                  align: "center"
              },

            // {
            //     title:'操作',
            //     align:'center',
            //     minWidth:100,
            //     render:(h,params) =>{
            //          return h(
            //             "div",
            //             {
            //                 style: {
            //                 textAlign: "left",
            //                 display:'flex',
            //                 justifyContent:'center'
            //                 },
            //             },
            //             [
            //                 h(
            //                 "Button",
            //                 {
            //                     attrs: {
            //                     type: "info",
            //                     size: "small",
            //                     },
            //                     on: {
            //                         click: () => {
            //                             this.editRow.name = params.row.deviceName
            //                             this.editRow.id = params.row.deviceId
            //                             if(this.brandList && this.brandList.length != 0){
            //                                 this.brandList.map(item =>{
            //                                     if(item.deviceType == params.row.deviceType){
            //                                         this.editRow.brandId = item.id
            //                                     }
            //                                 })
            //                             }else{
            //                                 this.editRow.brandId = ''
            //                             } 
            //                             this.editRow.status = params.row.runStatus
            //                             this.selectModal = true;
            //                         },
            //                     },
            //                     style: {
            //                         marginRight: "10px",
            //                         textAlign: "center",
            //                     },
            //                 },
            //                 "修改" //1001
            //                 ),       
            //                 h(
            //                 "Button",
            //                 {
            //                     attrs: {
            //                     type: "error",
            //                     size: "small",
            //                     },
            //                     on: {
            //                         click: () => {
            //                            this.$Modal.confirm({
            //                                title: "温馨提示",
            //                                 content: "<p>确认要删除该数据吗？</p>",
            //                                 onOk: () => {
            //                                     this.deleteRow(params.row.deviceId)
            //                                 },
            //                            })
            //                         },
            //                     },
            //                     style: {
            //                         marginRight: "10px",
            //                         textAlign: "center",
            //                     },
            //                 },
            //                 "删除" //1001
            //                 ),       
            //              ]
            //          );
            //     }
            // }
          ],
          tableData:[],
          loading:false,
          page:1,
          total:0,
          pageSize:20,
          currentPage:0,
          search:{
            name:'',
            code:''
          },

      }
    },
    //方法集合
    methods: {
        searchBtn(){
          this.page = 1,
          this.pageSize =20
          let data = {
            name:this.search.name,
            code:this.search.code,
            pageSize:this.pageSize,
            page:this.page,
            parentCode:''
          }
          this.getList(data)
        },
        restBtn(){
          this.search = {
            name:'',
            code:''
          }
          this.pageSize = 20,
          this.page = 1
          let data = {
            name:'',
            code:'',
            pageSize:this.pageSize,
            page:this.page,
            parentCode:''
          }
          this.getList(data)
        },
        hadlePageSize(val){
          let data = {
            pageSize:val.pageSize,
            page:val.page
          }
          this.getList(data)
        },

        // // 接口部分
        getList(data){
            this.$get('/device/api/device/deviceType/selectByPage',{
                code:data.code,
                name:data.name,
                page:data.page,
                pageSize:data.pageSize,
                parentCode:data.parentCode
            }).then(res=>{
                this.tableData  = res.dataList
                this.total = res.maxCount
                this.currentPage = res.currentPage
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      let data = {
        page:this.page,
        pageSize:this.pageSize,
        code:'',
        name:'',
        parentCode:''
      }
      this.getList(data)
    },
  }
</script>
<style lang='less' scoped>
  //编写css
</style>